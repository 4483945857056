export function calculateNetPrice(priceType: string, priceAmount: number | string, taxRate: number | string) {
  if (!priceType?.startsWith('gross_') || !priceAmount) return parseFloat((priceAmount || '0').toString());
  const amount = (typeof priceAmount === 'string' ? parseFloat(priceAmount) : priceAmount) || 0;
  const tax = typeof taxRate === 'string' ? parseFloat(taxRate) : taxRate || 0;
  return amount / (1 + tax);
}

export function calculateGrossPrice(priceType: string, priceAmount: number | string, taxRate: number | string) {
  if (!priceType?.startsWith('net_') || !priceAmount) return parseFloat((priceAmount || '0').toString());
  const amount = (typeof priceAmount === 'string' ? parseFloat(priceAmount) : priceAmount) || 0;
  const tax = typeof taxRate === 'string' ? parseFloat(taxRate) : taxRate || 0;
  return amount * (1 + tax);
}

export function calculateBasicPrice(
  priceAmount: number,
  priceQuantity: number,
  basicQuantity: number,
  contentQuantity: number
) {
  if (priceAmount === null) return 0;
  priceQuantity = priceQuantity || 1;
  basicQuantity = basicQuantity || 0;
  contentQuantity = contentQuantity || 1;
  return (priceAmount / priceQuantity) * (basicQuantity / contentQuantity);
}

export function getDisplayPrice(amount: number, currency: string, locale: string) {
  return amount.toLocaleString(locale, { style: 'currency', currency: currency || 'EUR' });
}
