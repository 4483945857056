<div *ngIf="model">
  <div class="container">
    <div class="row">
      <div class="col-sm">
        <div class="product-preview">
          <div class="thumbnail-list">
            <div *ngFor="let image of mimes" (mouseenter)="onThumbnailHover(image.normUri)" class="thumbnail-item">
              <span *ngIf="!image.normUri" class="glyphicon glyphicon-picture" style="font-size: 36px;"></span>
              <img *ngIf="image.normUri" [src]="image.normUri" class="thumbnail" />
            </div>
          </div>        
          <div class="main-image">
            <span *ngIf="!selectedImage" class="glyphicon glyphicon-picture" style="font-size: 36px;"></span>
            <img *ngIf="selectedImage" [src]="selectedImage" alt="Selected Product Image" class="preview-image" />
          </div>
        </div>  
        <div class="row technicalFeaturesDiv">
          <div *ngIf="model?.technicalFeatures && nonVariantFeatureExists">
            <h6>{{ 'Produkteigenschaften' | translate }}</h6>
            <div 
            id="productFeatures"
            class="expand-content"
            [class.expanded]="isTechnicalFeaturesExpanded"
            #technicalFeatures>
              <div *ngFor="let feature of model?.technicalFeatures">
                <span *ngIf="!feature.isVariant">
                    <b>{{feature.name}}:</b> {{feature.value}} {{feature.funit}}
                </span>
              </div>
            </div>
            <dx-button *ngIf="isTechnicalFeaturesOverflowing" 
              stylingMode="text" 
              type="default"  
              text="{{ isTechnicalFeaturesExpanded ? 'Weniger anzeigen' : 'Mehr' }}"
              (click)="toggleTechnicalFeatures()">
            </dx-button>
          </div>
        </div>       
      </div>
      <div class="col-sm product-infos">
        <h5>{{model?.descriptionShort}}</h5>
        <p>{{model?.marketingClaim}}</p>
        <hr>
        <div *ngIf="model?.price?.prouctPrice">
            <h6>{{model?.price.prouctPrice}} <span *ngIf="this.model.orderDetail.hasBasicPriceDuty" class="shopViewBasicPriceDuty"> ({{basicPriceDutyInfo}})</span></h6> 
        </div>   
        <div class="shoppingCart">
          <span class="shoppingCartOrderDetails">{{model?.orderDetail.quantityMinNumber}} {{orderUnit}}</span>
          <dx-button
            icon="cart"
            text="{{ 'In den Warekorb' | translate }}"
            hint="Dient nur zur Darstellung"
          >
          </dx-button>
        </div>
        <div *ngIf="model?.technicalFeatures">
          <div *ngFor="let feature of model?.technicalFeatures">
            <div class="variantDiv" *ngIf="feature.isVariant">
              <dx-select-box
                [id]="feature.name"
                [items]="getVariantValues(feature.name)"
                displayExpr="value"
                valueExpr="value"
                [label]="feature.name"
                width="30%"
                height="40px"
                [(value)]="feature.value"
                (onValueChanged)="onVariantValueChanged($event)"
                itemTemplate="item">
              </dx-select-box>            
              <div *dxTemplate="let data of 'item'">
                <div style="display:inline-block">{{data.value}}</div>
              </div>
            </div>
          </div>
        </div>  
        <div class="shopviewProductInformations">
          <h6>{{ 'Produktinformationen' | translate }}</h6>          
          <div *ngIf="model.supplierPid.length>0"><b>{{ 'Artikelnummer' | translate }}: </b>{{model.supplierPid}}</div>
          <div *ngIf="model.internationalPid?.length>0"><b>{{ 'Internationale ID' | translate }}: </b>{{model.internationalPid}}</div>
          <div *ngIf="model.manufacturerAid?.length>0"><b>{{ 'Herstellernummer' | translate }}: </b>{{model.manufacturerAid}}</div>
          <div *ngIf="model.manufacturerName?.length>0"><b>{{ 'Hersteller' | translate }}: </b>{{model.manufacturerName}}</div>
        </div>  
        <div *ngIf="model?.productDataSheets.length > 0">
          <hr>
          <h6>{{ 'Produkdatenblätter' | translate }}</h6>
          <ul style="list-style: none;padding-left:0px;">
            <li *ngFor='let download of model.productDataSheets' class="dataSheetDownload"><a href="{{mediaService.getDownloadLink(this.catalogService.catalog.id,this.model.customerId,download.mimeSource)}}" target="_blank"><img src="/images/Adobe_PDF_icon.svg" style="height: 20px;margin-right: 10px;">{{download.mimeDescr}}</a></li>
          </ul>
        </div>
        <div *ngIf="model?.topFeatures?.length > 0" class="topFeatures"> 
          <hr>
          <h6>{{ 'Topmerkmale' | translate }}</h6>
          <ul>
            <li *ngFor="let topFeature of model.topFeatures">
              {{topFeature.value}}
            </li>
          </ul>
        </div>        
      </div>
    </div>
    
    <div class="row descriptionLongDiv">
      <div *ngIf="model?.descriptionLong">
        <hr>
        <h6>{{ 'Beschreibung' | translate }}</h6>
        <div 
          id="descriptionContent"
          class="expand-content"
          [class.expanded]="isDescriptonExpanded"
          #descriptionLong>
          <span [innerHTML]="model?.descriptionLong"></span>
        </div>
        <dx-button *ngIf="isDescriptionOverflowing"
          stylingMode="text" 
          type="default" 
          text="{{ isDescriptonExpanded ? 'Weniger anzeigen' : 'Mehr' }}"  
          (click)="toggleDescription()">
        </dx-button>
      </div>
    </div>
    <div *ngIf="model?.references?.length > 0" class="row prouduct-refernces">      
      <dx-tab-panel
        [dataSource]="referencePages"
      >
        <div *dxTemplate="let item of 'item'" class="row referenceItemTemplate">
          <div *ngFor="let data of item.data" class="col-sm referenceItem">
            <div (click)="referenceClicked(data)">
              <div class="thumbnail-item">
                <span *ngIf="!data.normUri" class="glyphicon glyphicon-picture" style="font-size: 36px;"></span>
                <img *ngIf="data.normUri" [src]="data.normUri" class="thumbnail" />
              </div>
              {{data.descriptionShort}}
            </div>
          </div>
        </div>
      </dx-tab-panel>
    </div>
  </div>
</div>
